<template>
	<section v-loading="pageLoading" class="staff">
		<el-tabs class="infoTab recruitTab" v-model="activeName" @tab-click="changeTab">
			<el-tab-pane name="0" :label="`待面试(${_returnNumber(0)})`"></el-tab-pane>
			<el-tab-pane name="1" :label="`已面试(${_returnNumber(1)})`"></el-tab-pane>
			<el-tab-pane name="2" :label="`已评估(${_returnNumber(2)})`"></el-tab-pane>
		</el-tabs>
		<el-form style="margin: 0px 16px 0; padding: 20px 30px 0; background-color: #fff" inline :model="searchForm" size="small">
			<el-form-item label="面试时间">
				<el-date-picker
					v-model="searchForm.startTime"
					size="small"
					default-time="09:30:00"
					type="date"
					value-format="yyyy-MM-dd HH:mm:ss"
					format="yyyy-MM-dd"
					clearable
				></el-date-picker>
				<span style="margin: 0 5px">-</span>
				<el-date-picker
					v-model="searchForm.endTime"
					size="small"
					default-time="18:30:00"
					type="date"
					value-format="yyyy-MM-dd HH:mm:ss"
					format="yyyy-MM-dd"
					clearable
				></el-date-picker>
			</el-form-item>
			<el-button type="primary" size="small" icon="el-icon-search" style="margin-left: 5px" @click="getList">查询</el-button>
		</el-form>
		<div style="margin: 0 16px; padding: 0 30px; background-color: #fff; overflow: hidden">
			<el-table :data="tableData" v-if="activeName != 2" border stripe style="width: 100%">
				<el-table-column label="序号" align="center" width="80">
					<template v-slot="{ $index }">{{ $index + 1 }}</template>
				</el-table-column>
				<el-table-column label="基本信息" align="center">
					<template slot-scope="{ row }">
						<p>{{ row.name }} {{ row.sex == '1' ? '♂' : '♀' }} {{ row.yearsWorking }}·{{ row.highestEducationName }}</p>
						<p class="phoneEmail">{{ row.phoneNumber }}/{{ row.mailbox }}</p>
					</template>
				</el-table-column>
				<el-table-column label="毕业院校/专业" align="center">
					<template slot-scope="{ row }"> {{ row.graduatedSchool }}/{{ row.industry }} </template>
				</el-table-column>
				<el-table-column label="应聘岗位" align="center" prop="jobName"></el-table-column>
				<el-table-column label="投递渠道" align="center" prop="sources"></el-table-column>
				<el-table-column label="标签" align="center" prop="labelNames"></el-table-column>
				<el-table-column label="面试轮次" align="center" prop="interviewRoundsName"></el-table-column>
				<el-table-column label="面试时间" align="center" prop="interviewTime" width="160"></el-table-column>
				<el-table-column label="操作" align="center" v-if="activeName == 0" width="200">
					<template v-slot="{ row }">
						<!--<el-popover placement="left" width="96" trigger="click">
              <el-button type="text" slot="reference">
                <img src="../../assets/button/操作图标@3x.png" width="16px" />
              </el-button>
              <ul class="drop-menu">
                <li
                  class="menu-item"
                  v-for="(item, i) in commands"
                  v-show="showItem(row,item)"
                  @click="operate(row, item)"
                  :key="i"
                >
                  {{ item.label }}
                </li>
              </ul>
            </el-popover>!-->
						<el-button type="text" v-for="(item, i) in commands" v-show="showItem(row, item)" @click="operate(row, item)" :key="i">
							{{ item.label }}
						</el-button>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" v-else-if="activeName == 1">
					<template v-slot="{ row }">
						<el-button
							type="text"
							@click="
								$router.push({
									name: 'interviewAppraisal',
									params: { arrangeId: row.arrangeId, processId: row.processId, name: row.name }
								})
							"
							>评价</el-button
						>
						<el-button type="text" @click="$router.push({ name: 'ResumeInfo', params: { id: row.id } })">简历详情</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-table :data="tableData" @row-click="rowclick" border stripe style="width: 100%" v-else>
				<el-table-column label="序号" align="center" width="80">
					<template v-slot="{ $index }">{{ $index + 1 }}</template>
				</el-table-column>
				<el-table-column label="基本信息" align="center">
					<template slot-scope="{ row }">
						<p>{{ row.name }} {{ row.sex == '1' ? '♂' : '♀' }} {{ row.yearsWorking }}·{{ row.highestEducationName }}</p>
						<p class="phoneEmail">{{ row.phoneNumber }}/{{ row.mailbox }}</p>
					</template>
				</el-table-column>
				<el-table-column label="毕业院校/专业" align="center">
					<template slot-scope="{ row }"> {{ row.graduatedSchool }}/{{ row.industry }} </template>
				</el-table-column>
				<el-table-column label="应聘岗位" align="center" prop="jobName"></el-table-column>
				<el-table-column label="投递渠道" align="center" prop="sources"></el-table-column>
				<el-table-column label="标签" align="center" prop="labelNames"></el-table-column>
				<el-table-column label="面试轮次" align="center" prop="interviewRoundsName"></el-table-column>
				<el-table-column label="面试时间" align="center" prop="interviewTime"></el-table-column>
			</el-table>
			<el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="table.pageNo"
				:page-sizes="[20, 40, 80, 100]"
				:page-size="table.pageSize"
				style="float: right; margin: 20px 0"
				layout="total, sizes, prev, pager, next, jumper"
				:total="table.totalNum"
			></el-pagination>
		</div>
		<el-dialog :title="digTitle" :visible.sync="dig" width="40%" center>
			<div class="skillword">{{ text }}</div>
			<span slot="footer">
				<el-button type="primary" @click="dig = false">知道了</el-button>
			</span>
		</el-dialog>
	</section>
</template>
<script>
import { interviewApprovalList, interviewApproval, interviewApprovalNum } from '../../api/recruitment_allocation';
export default {
	data() {
		return {
			pageLoading: false,
			dig: false,
			activeName: '0',
			tabOptions: [
				{
					label: '待面试',
					name: '0'
				},
				{
					label: '已面试',
					name: '1'
				},
				{
					label: '已评估',
					name: '2'
				}
			],
			commands: [
				{ label: '面试', value: '1' },
				{ label: '岗位技能', value: '2' },
				{ label: '打印简历', value: '3' }
			],
			searchForm: {
				startTime: '',
				endTime: ''
			},
			numList: [],
			table: {
				pageNo: 1,
				pageSize: 20,
				totalNum: 0
			},
			dateRange: '',
			text:
				'熟练掌握JavaScript语言,能够脱离各JS类库后熟练的进行跨浏览器开发熟悉各种Web前端技术,包括HTML/XML/CSS等,有基于Ajax的RIA应用开发经验至少精通一门非Web前端脚本的语言(如Java/Ruby/PHP/C++),并有项目经验对前端技术有持续的热情,个性乐观开朗,逻辑性强,善于和各种背景的人合作具有ES5/HTML5/CSS3/移动设备上前端开发/NodeJS等开发经验者优先3年及以上互联网产品前端开发经验，2年以上移动互联网产品前端开发经验',
			digTitle: 'Web前端岗位技能',
			tableData: []
		};
	},
	// beforeRouteLeave(to, from, next){
	//   sessionStorage.setItem(`${this.$route.name}obj`,JSON.stringify({activeName:this.activeName}));
	//   next()
	// },
	methods: {
		getNum() {
			interviewApprovalNum().then((res) => {
				delete res._responseStatusCode;
				this.numList = res;
			});
		},
		_returnNumber(status) {
			if (this.numList && this.numList.length >= 1) {
				for (const key in this.numList) {
					if (this.numList[key].status == status) {
						return this.numList[key].count;
					}
				}
				return 0;
			}
			return 0;
		},
		rowclick(row) {
			this.$router.push({ name: 'interviewAppraisalInfo', query: { arrangeId: row.arrangeId, name: row.name, processId: row.processId } });
		},
		operate(row, item) {
			if (item.value == '1') {
				this.$confirm(`您确认对${row.name}进行面试吗`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				})
					.then(() => {
						interviewApproval({ arrangeId: row.arrangeId, processId: row.processId }).then((res) => {
							if (res && res._responseStatusCode == 0) {
								this.getNum();
								this.getList();
								this.$message.success('面试开始，面试结束后请在已面试列表填写评估');
							}
						});
					})
					.catch(() => {});
			} else if (item.value == '2') {
				this.digTitle = `${row.jobName}岗位技能`;
				this.text = row.skillDemand;
				this.dig = true;
			} else {
				this.$router.push({ name: 'RecruitPrint', params: { id: row.id } });
			}
		},
		changeTab(tab) {
			this.getNum();
			this.getList(tab.name);
		},
		showItem(row, item) {
			if (!row.skillDemand && item.value == '2') {
				return false;
			}
			return true;
		},
		getList() {
			this.pageLoading = true;
			interviewApprovalList({ ...this.searchForm, pageNo: this.table.pageNo, pageSize: this.table.pageSize, queryType: this.activeName }).then(
				(res) => {
					if (res) {
						this.tableData = res.list;
						this.table.totalNum = res.totalNum;
						this.pageLoading = false;
					}
				}
			);
		},
		handleSizeChange(val) {
			this.table.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.table.pageNo = val;
			this.getList();
		}
	},
	beforeRouteLeave(form, to, next) {
		sessionStorage.setItem(
			`${this.$route.name}Data`,
			JSON.stringify({
				searchForm: this.searchForm,
				activeName: this.activeName
			})
		);
		next();
	},
	mounted() {
		const params = JSON.parse(sessionStorage.getItem(`${this.$route.name}Data`));

		if (params && params.activeName) {
			this.activeName = params.activeName;
		}
		if (params && params.searchForm) {
			this.searchForm = params.searchForm;
		}
		this.getNum();
		this.getList();
		// let activeName=JSON.parse(sessionStorage.getItem(`${this.$route.name}obj`)).activeName
		// if(activeName)this.activeName=activeName;
	}
};
</script>

<style lang="scss" scoped>
.search_box {
	margin: 20px 10px;
	padding-left: 10px;
	> span {
		font-size: 14px;
		margin: 0 10px;
	}
	.fr {
		margin-right: 10px;
	}
}
.skillword {
	font-size: 16px;
	text-align: center;
	line-height: 25px;
	margin-bottom: 15px;
}
::v-deep.el-form--inline .el-form-item {
	vertical-align: initial;
}
::v-deep.el-form-item__label {
	font-weight: normal;
}
::v-deep.el-table th,
.el-table tr {
	background-color: #f0f8ff;
}
</style>
<style lang="scss">
.staff {
	.el-dialog__footer {
		text-align: center;
	}
	.recruitTab {
		.el-tabs__header {
			padding: 20px 30px 0;
		}
	}
}
</style>
